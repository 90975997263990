<template>
  <div>
    <Footer />
    <div class="content">
      <p class="contenTitle">账号注册</p>
      <p style="text-align: center">
        <img class="cross" src="./register/cross.png" alt="" />
      </p>
      <p style="text-align: center">
        <span class="contentSpan">姓名：</span
        ><input type="text" class="contentInput" v-model="real_name" @blur="real_name=$event.target.value.trim()" />
        &emsp;&emsp;

        <span class="contentSpan">手机号：</span>
        <input
          type="text"
          class="contentInput"
          v-model="telephone"
          @blur.native.capture="sendcode()"
        />
      </p>

      <p style="text-align: center">
        <span class="contentSpan">学校名称：</span
        ><input type="text" class="contentInput"  v-model="school"   @blur="school=$event.target.value.trim()" />
        &emsp;&emsp;
        <span class="contentSpan">身份证号：</span
        ><input
          type="text"
          class="contentInput"
          @blur.native.capture="isId_num()"
          v-model="id_num"
        />
      </p>

      <p style="text-align: center">
        <span class="contentSpan">密码：</span>
        <input
          type="password"
          class="contentInput"
          v-model="password"
          @blur.native.capture="isPassword()"
        />
        <!-- <el-input class="contentInput" v-model="password" show-password :blur="isPassword()"></el-input> -->
        &emsp;&emsp;
        <span class="contentSpan">确认密码：</span>
        <el-input
          class="contentInput"
          v-model="Qpassword"
          show-password
        ></el-input>
      </p>

      <p style="text-align: center">
        <span class="contentSpan">作品全称：</span>
        <input type="text" class="contentInput" v-model="works"  @blur="works=$event.target.value.trim()" />
        &emsp;&emsp;
        <span class="contentSpan">您的邮箱：</span>
        <input
          type="text"
          class="contentInput"
          v-model="email"
          @blur.native.capture="isEmail()"
        />
      </p>

      <p style="text-align: center">
        <span class="contentSpan">学历：</span>
        <input type="text" class="contentInput" v-model="grade" />
        &emsp;&emsp;
        <span class="contentSpan">专业：</span>
        <input type="text" class="contentInput" v-model="major" />
      </p>
      <div class="footer">
        <el-upload
          ref="upload"
          action=""
          :http-request="httpRequest"
          multiple
          :limit="4"
          :on-remove="handleRemove"
          :on-change="onChange"
          :before-upload="beforeAvatarUpload"
          :on-success="handleUploadSuccess"
          :file-list="fileList"
        >
          <el-button type="warning" size="mini">上传附件</el-button>
          <div class="el-upload__tip">
            <!-- 注：请上传《XX
            学校第十九届“挑战杯”竞赛“揭榜挂帅”专项赛作品申报书》和
            <br />&emsp;&ensp; 《XX
            学校第十九届“挑战杯”竞赛“揭榜挂帅”专项赛报名信息统计表》 -->
            注：请上传附件（模板见官方文件）和报名系统审核通过的盖章版参赛报名表<br/>
            附件1.XX学校第十九届“挑战杯”竞赛“揭榜挂帅”专项赛作品申报书<br/>
            附件2.XX学校第十九届“挑战杯”竞赛“揭榜挂帅”专项赛报名信息统计表<br/>
            附件3.参赛方案原创性及保密性声明
          </div>
        </el-upload>
      </div>

      <p style="text-align: center">
        <el-button type="info" id="contentBut" @click="step"
          >注&emsp;册</el-button
        >
      </p>
      <div class="contentText">
        <span
          >大赛注册及报名信息收集仅用于大赛组委会审核与竞赛，我们将高度重视您的个人信息安全，并采取符合法律法规的安全措施，保护您的个人信息，防止发生个人信息的泄露、丢失、等安全风险。</span
        >
      </div>
    </div>

    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="20%"
      :before-close="handleClose"
    >
      <div style="padding: 0.1rem">
        我们已收到您的注册信息，审核通过后即可登录！
      </div>
      <div style="margin-top: 0.2rem; text-align: right">
        <el-button type="primary" @click="jump">确 定</el-button>
      </div>
    </el-dialog>
    <Bottom />
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import { method } from "lodash";
import { getLogin } from "api/apis.js";
// import {client,headers} from '@/tools/oss.js'
import { httpRequest } from "@/tools/common.js";
import { Search } from "@element-plus/icons-vue";
export default {
  name: "Register",
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      grade: "", //学历
      major: "", //专业
      works: "", //作品
      telephone: "", //手机号
      real_name: "", //真实姓名
      email: "", //邮箱
      id_num: "", //身份证号
      password: "", //密码
      Qpassword: "", //确认密码
      school: "", //学校
      Get_list: [], //名称收集
      accessory: [], //路径收集
      showif: true,
      iFshowPhone: false,
      iFshowEmail: false,
      iFshowPassword: false,
      isFormID: false,
      fileList: [],
      Search: Search,
      time: new Date(),
      dialogVisible: false,
      isForm: false,
      file_Aarray: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 手机验证
    sendcode() {
      let reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
      if (this.telephone == "") {
        this.$message.error("请输入手机号码");
      } else if (!reg.test(this.telephone)) {
        this.$message.error("手机格式不正确");
        this.iFshowPhone = false;
      } else {
        this.iFshowPhone = true;
      }
    },
    // 邮箱验证
    isEmail() {
      let myreg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (this.email == "") {
        this.$message.error("请输入邮箱");
      } else if (!myreg.test(this.email)) {
        this.$message.error("邮箱格式不正确");
        this.iFshowEmail = false;
      } else {
        this.iFshowEmail = true;
      }
    },
    // 密码验证
    isPassword() {
      if (this.password.length < 6) {
        this.$message.error("密码不能少于6位数");
        this.iFshowPassword = false;
      } else {
        this.iFshowPassword = true;
      }
    },
    // 身份证验证
    isId_num() {
      let myreg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (this.id_num == "") {
        this.$message.error("请输入身份证");
      } else if (!myreg.test(this.id_num)) {
        this.$message.error("身份证格式不正确");
        this.isFormID = false;
      } else {
        this.isFormID = true;
      }
    },
    // 上传的方法
    onChange(file, fileList) {
      this.file_List = fileList;
      // console.log(this.file_Array)
      // this.fileList = fileList;
    },
    handleUploadSuccess(response, file, fileList) {
      console.log(response, file, fileList, "response, file, fileList");
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      delete this.file_Aarray[file.uid];
      console.log(this.file_Aarray);
    },
    beforeAvatarUpload(file) {
      // 文件大小校验
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("文件大小不能超过 10M !");
      }
      return isLt2M;
    },

    //回调接收上传成功的参数
    getHttpRelut(res, file) {
      file = file.file;
      file.url = res.url;
      console.log(file);
      // console.log(file.url)
      this.file_Aarray[file.uid] = file;
      console.log(this.file_Aarray);
    },
    //上传时的oss
    // user_register// 用户注册
    // sign_up_audit// 审核
    httpRequest(file) {
      httpRequest(file, "user_register", this.getHttpRelut);
    },

    // 下一步
    step() {
      if (
        this.telephone === "" ||
        this.real_name === "" ||
        this.email === "" ||
        this.id_num === "" ||
        this.password === "" ||
        this.Qpassword === "" ||
        this.school === "" ||
        this.grade === "" ||
        this.major === "" ||
        this.works === "" ||
        Object.keys(this.file_Aarray).length == 0
      ) {
        this.$message.error("请输入完整信息!");
        return false;
      }
      if (this.password != this.Qpassword) {
        this.$message.error("两次密码输入不一致！");
        return false;
      }
      if (
        this.iFshowPhone &&
        this.iFshowEmail &&
        this.iFshowPassword &&
        this.isFormID
      ) {
        this.submit();
      } else {
        this.$message.error("请检查信息格式是否有误");
      }
    },
    //点击提交时触发
    // grade:"",//学历
    // major:"",//专业
    // works:"",//作品
    // telephone: "", //手机号
    // real_name: "", //真实姓名
    // email: "", //邮箱
    // id_num: "", //身份证号
    // password: "", //密码
    // Qpassword: "", //确认密码
    // school: "", //学校
    submit() {
      let accessory = [];
      for (let key in this.file_Aarray) {
        if (this.file_Aarray.hasOwnProperty(key)) {
          accessory.push({
            name: this.file_Aarray[key].name,
            url: this.file_Aarray[key].url,
          });
          console.log(accessory); // foo
        }
      }
      let dataobj = new FormData();
      dataobj.append("telephone", this.telephone); //手机号
      dataobj.append("real_name", this.real_name); //真实姓名
      dataobj.append("email", this.email); //邮箱
      dataobj.append("id_num", this.id_num); //身份证号
      dataobj.append("password", this.password); //密码
      dataobj.append("school", this.school); ////学校
      dataobj.append("works_name", this.works); //作品名称
      dataobj.append("education_background", this.grade); //学历
      dataobj.append("major", this.major); //专业
      dataobj.append("accessory", JSON.stringify(accessory)); //上传附件

      getLogin.Registered(dataobj).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$store.state.User == res.data;
          this.$message({
            message: "恭喜您,注册成功！",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/Register"); 
          }, 2000);
        }else if(res.code != 200){
          this.$message.error(res.msg);
        }
      });
    },
    jump() {
      setTimeout(() => {
        this.$router.push("/HomePage");
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .el-upload__tip {
  font-size: 0.14rem;
  margin: auto 0.1rem;
}

:deep .el-upload {
  margin-left: 2.6rem;
}

:deep .el-upload > button {
  font-size: 0.14rem;
  padding: 0.12rem;
}

:deep .el-upload-list--picture .el-upload-list__item {
  width: 2rem;
  margin: auto;
}

input[readonly] {
  color: #929292;
}

:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color))
    inset;
  cursor: default;

  .el-input__inner {
    cursor: default !important;
  }
}

:deep .el-upload-list__item-info {
  margin-left: 3.2rem;
  display: inline-block;
}

/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

@import "./registered.less";
</style>
